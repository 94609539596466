import AuthTemplate from '../components/auth/AuthTemplate';
import RegisterForm from '../containers/auth/RegisterForm';

const RegisterPage = () => {
  return (
    <div className="RegisterPage">
      <AuthTemplate>
        <RegisterForm type="register" />
      </AuthTemplate>
    </div>
  );
};

export default RegisterPage;