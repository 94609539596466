import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import PostViewer from '../../components/post/PostViewer';
import { useParams, useNavigate } from 'react-router-dom';
import { readPost, unloadPost } from '../../modules/post';
import PostActionButtons from '../../components/post/PostActionButtons';
import { setOriginalPost } from '../../modules/write';
import user from '../../modules/user';
import { removePost } from '../../lib/api/posts';

const PostViewerContainer = () => {
  const { post, error, loading } = useSelector(({ post, loading, user }) => ({
    post: post.post,
    error: post.error,
    loading: loading['post/READ_POST'],
    user: user.user
  }));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { postId } = useParams();

  const onEdit = () => {
    dispatch(setOriginalPost(post.data));
    navigate('/write');
  };

  const onRemove = async () => {
    try {
      await removePost(postId);
      navigate('/');
    } catch (e) {
      console.log(e);
    }
  };

  // const ownPost = (user && user._id) === (post && post.data.user._id);
  const ownPost = (post && post.data.user._id);
  console.log(user);

  useEffect(() => {
    dispatch(readPost(postId));

    return () => {
      dispatch(unloadPost());
    }
  }, [dispatch, postId]);

  return <PostViewer
    post={post}
    error={error}
    loading={loading}
    actionButtons={ownPost && <PostActionButtons onEdit={onEdit} onRemove={onRemove} />}    
  />  
};

export default PostViewerContainer;