// import client from '../../lib/api/client';
import axios from 'axios';
import { useEffect } from 'react';

const Test = () => {
  // const client = axios.create({ timeout: 50000 });

  console.log('&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&');
  console.log('Test started!');
  // const id = 66f3bc8cfe2fd779bd5f1016;
  // client.get(`/api/posts/${id}`);
  // const timeout = 5000;
  // client.get('/api/posts/66f3bc8cfe2fd779bd5f1016', { timeout });
  // client.get('/api/posts/66f3bc8cfe2fd779bd5f1016');
  // client.get('/api/auth/check');
  // client.get('/api/posts?username=neuropathy');

  useEffect(() => {
    const client = axios.create({ timeout: 50000 });
    client.get('/api/posts/66f3bc8cfe2fd779bd5f1016');
  }, []);

  return (
    <div className="Test">
      Test
    </div>
  );
};

export default Test;