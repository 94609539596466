import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Editor from '../../components/write/Editor';
import { initialize, changeField } from '../../modules/write';

const EditorContainer = () => {
  const { title, body } = useSelector(({ write }) => ({
    title: write.title,
    body: write.body
  }));
  const dispatch = useDispatch();

  const onChangeField = useCallback(payload => {
    dispatch(changeField(payload));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(initialize());
    }
  }, [dispatch]);

  return (
    <Editor onChangeField={onChangeField} title={title} body={body} />
  );
};

export default EditorContainer;