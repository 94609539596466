// import Quill from 'quill';
// import { useEffect, useRef } from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';
import palette from '../../lib/styles/palette';
import Responsive from '../common/Responsive';
// import ReactQuill from 'react-quill-new';
// import 'react-quill-new/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EditorBlock = styled(Responsive)`
  padding-top: 5rem;
  padding-bottom: 5rem;
`;

// const QuillWrapper = styled.div`
//   ql-editor: {
//     padding: 0;
//     min-height: 320px;
//     font-size: 1.125rem;
//     line-height: 1.5;
//   }
//   /* .ql-editor.ql-blank::before {
//     left: 0px;
//   } */
// `;

const TitleInput = styled.input`
  font-size: 3rem;
  outline: none;
  padding-bottom: 0.5rem;
  border: none;
  border-bottom: 1px solid ${palette.gray[4]};
  margin-bottom: 2rem;
  width: 100%;
`;

const Editor = ({ title, body, onChangeField }) => {
  // const quillElement = useRef(null);
  // const quillInstance = useRef(null);

  // useEffect(() => {
  //   quillInstance.current = new Quill(quillElement.current, {
  //     theme: 'bubble',
  //     placeholder: '내용을 작성하세요',
  //     modules: {
  //       toolbar: [
  //         [{ header: '1' }, { header: '2' }],
  //         ['bold', 'italic', 'underline', 'strike'],
  //         [{ list: 'ordered' }, { list: 'buller' }],
  //         ['blockquote', 'code-block', 'link', 'image']
  //       ]
  //     }
  //   })
  // }, []);

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'buller' }],
        ['blockquote', 'code-block', 'link', 'image']
      ]
    }
  }), []);

  const onChangeTitle = e => {
    onChangeField({ key: 'title', value: e.target.value });
  };

  const onChangeBody = text => {
    console.log('on change body called');
    console.log('text', text);
    onChangeField({ key: 'body', value: text });
  };

  return (
    <EditorBlock>
      <TitleInput
        placeholder="제목을 입력하세요"
        onChange={onChangeTitle}
        value={title}
      />
      <ReactQuill
        theme="snow"
        modules={modules}
        placeholder="내용을 작성하세요"
        onChange={onChangeBody}
        value={body}
      />
    </EditorBlock>
  );
};

export default Editor;