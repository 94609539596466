import HeaderContainer from "../containers/common/HeaderContainer";
import PostViewerContainer from "../containers/post/PostViewerContainer";

const PostPage = () => {
  return (
    <div className="PostPage">
      <HeaderContainer />
      <PostViewerContainer />
    </div>
  );
};

export default PostPage;