import { Routes, Route } from 'react-router-dom';
import RegisterPage from './pages/RegisterPage';
import PostListPage from './pages/PostListPage';
import LoginPage from './pages/LoginPage';
import WritePage from './pages/WritePage';
import PostPage from './pages/PostPage';
import TestPage from './pages/TestPage';
import { StyleSheetManager } from 'styled-components';
import { Helmet } from 'react-helmet-async';

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>REACTERS</title>
      </Helmet>
      <StyleSheetManager shouldForwardProp={prop => true}>
        <Routes>
          <Route path="/" element={<PostListPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/write" element={<WritePage />} />
          <Route path="/:username">
            <Route index element={<PostListPage />} />
            <Route path=":postId" element={<PostPage />} />
          </Route>
          <Route path="/test" element={<TestPage />} />
        </Routes>
      </StyleSheetManager>
    </div>
  );
}

export default App;
