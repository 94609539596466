import Responsive from '../components/common/Responsive';
import Test from '../components/test/Test';

const TestPage = () => {
  return (
    <div className="TestPage">
      <Responsive>
        <Test />
      </Responsive>
    </div>
  );
};

export default TestPage;