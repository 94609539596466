import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { listPosts } from '../../modules/posts';
import PostList from '../../components/posts/PostList';

const PostListContainer = () => {
  const { username } = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { posts, error, loading, user } = useSelector(
    ({ loading, user, posts }) => ({
      posts: posts.posts,
      error: posts.error,
      user: user.user,
      loading: loading['posts/LIST_POSTS']
  }));

  useEffect(() => {
    const page = parseInt(searchParams.get('page'), 10) || 1;
    const tag = searchParams.get('tag');
    dispatch(listPosts({ page, username, tag }));
  }, [dispatch, username, searchParams]);

  return (
    <PostList
      loading={loading}
      posts={posts}
      error={error}
      showWriteButton={user}
    />
  );
};

export default PostListContainer;