import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import Pagination from '../../components/posts/Pagination';

const PaginationContainer = () => {
  const [searchParams] = useSearchParams();
  
  const { username } = useParams();
  const tag = searchParams.get('tag');
  const page = parseInt(searchParams.get('page'), 10) || 1;

  console.log('page', page);

  const { posts, lastPage, loading } = useSelector(({ loading, posts }) => ({
    posts: posts.posts,
    lastPage: posts.lastPage,
    loading: loading['posts/LIST_POSTS']
  }));

  if (!posts || loading) return null;

  return (
    <Pagination
      page={parseInt(page, 10)}
      lastPage={lastPage}
      username={username}
      tag={tag}
    />
  );
};

export default PaginationContainer;